import { User } from "app/core/user/user.types";
import { UserModel } from "../domain/models/user.model";
import { AssignmentEntity } from "../repositories/assignment/entities/assignment-entity";
import { UserEntity } from "../repositories/user/entities/user-entity";

export class DocImplementationRepositoryMapper {
    mapDoc(param: any): UserEntity {
        const doc = param.data();
        return {
            id: param.id,
            ...doc
        }
    }

    mapDocUserToUser(param: UserModel): User {
        return {
            name: param.fullName,
            ...param
        }
    }
}


export class DocAssignmentImplementationRepositoryMapper {
    mapDoc(param: any): AssignmentEntity {
        const doc = param.data();
        return {
            id: param.id,
            ...doc
        }
    }

    mapDocUserToUser(param: UserModel): User {
        return {
            name: param.fullName,
            ...param
        }
    }
}