// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyCqM-4_8GHhq-qXabGlmS2JkVUMwoYrxB8",
        authDomain: "catastro-leona-vicario.firebaseapp.com",
        projectId: "catastro-leona-vicario",
        storageBucket: "catastro-leona-vicario.appspot.com",
        messagingSenderId: "875561638754",
        appId: "1:875561638754:web:30914600904b1ec28fea3b",
        measurementId: "G-DYT6C7JPRC"
    },
    defaultPassword: 'P3s5w0r6',
    pathInvoiceAssignment: 'invoiceForAssigments',
    pathInvoiceSubdivision: 'invoiceForSubdivisions',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
