<div [formGroup]="parentGroup">
    <mat-form-field class="w-full">
        <mat-label>{{label}}</mat-label>
        <input
            [name]="name"
            [formControlName]="name"
            matInput
            [type]="type"
            [placeholder]="placeholder">
        <mat-icon
            *ngIf="svgIcon"
            class="icon-size-5"
            matPrefix
            [svgIcon]="svgIcon"></mat-icon>
        
            <mat-error *ngIf="parentGroup.get(name).hasError('required')">
                El campo {{ label }} es obligatorio.
            </mat-error>

            <mat-error *ngIf="parentGroup.get(name).hasError('email')">
                Por favor, ingresa un correo electrónico válido.
            </mat-error>

            <mat-error *ngIf="parentGroup.get(name).hasError('pattern')">
                Solamente puedes ingresar números
            </mat-error>
        
    </mat-form-field>
</div>
