import { ChangeDetectorRef, Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation, navigationForEditor, navigationForOwner } from 'app/mock-api/common/navigation/data';
import { UserService } from 'app/core/user/user.service';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';

@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi
{
    private  _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private  _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private  _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private  _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    private  _compactNavigationOwner: FuseNavigationItem[] = navigationForOwner;
    private  _defaultNavigationOwner: FuseNavigationItem[] = navigationForOwner;
    private  _futuristicNavigationOwner: FuseNavigationItem[] = navigationForOwner;
    private  _horizontalNavigationOwner: FuseNavigationItem[] = navigationForOwner;

    private  _compactNavigationEditor: FuseNavigationItem[] = navigationForEditor;
    private  _defaultNavigationEditor: FuseNavigationItem[] = navigationForEditor;
    private  _futuristicNavigationEditor: FuseNavigationItem[] = navigationForEditor;
    private  _horizontalNavigationEditor: FuseNavigationItem[] = navigationForEditor;
    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService, 
        )
    {
        // Register Mock API handlers
        this.registerHandlers();
        this.registerHandlersForOwner();
        this.registerHandlersForEditors();
        //console.log('Load images');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {

        // Mark for check
        this._fuseMockApiService
        .onGet('api/common/navigation')
        .reply(() => {

            // Fill compact navigation children using the default navigation
            this._compactNavigation.forEach((compactNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if ( defaultNavItem.id === compactNavItem.id )
                    {
                        compactNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill futuristic navigation children using the default navigation
            this._futuristicNavigation.forEach((futuristicNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if ( defaultNavItem.id === futuristicNavItem.id )
                    {
                        futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill horizontal navigation children using the default navigation
            this._horizontalNavigation.forEach((horizontalNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if ( defaultNavItem.id === horizontalNavItem.id )
                    {
                        horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Return the response
            return [
                200,
                {
                    compact   : cloneDeep(this._compactNavigation),
                    default   : cloneDeep(this._defaultNavigation),
                    futuristic: cloneDeep(this._futuristicNavigation),
                    horizontal: cloneDeep(this._horizontalNavigation)
                }
            ];
        });
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        
    }

     /**
     * Register Mock API handlers
     */
     registerHandlersForOwner(): void
     {
 
         // Mark for check
         this._fuseMockApiService
         .onGet('api/common/navigation/owners')
         .reply(() => {
 
             // Fill compact navigation children using the default navigation
             this._compactNavigationOwner.forEach((compactNavItem) => {
                 this._defaultNavigationOwner.forEach((defaultNavItem) => {
                     if ( defaultNavItem.id === compactNavItem.id )
                     {
                         compactNavItem.children = cloneDeep(defaultNavItem.children);
                     }
                 });
             });
 
             // Fill futuristic navigation children using the default navigation
             this._futuristicNavigationOwner.forEach((futuristicNavItem) => {
                 this._defaultNavigationOwner.forEach((defaultNavItem) => {
                     if ( defaultNavItem.id === futuristicNavItem.id )
                     {
                         futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                     }
                 });
             });
 
             // Fill horizontal navigation children using the default navigation
             this._horizontalNavigationOwner.forEach((horizontalNavItem) => {
                 this._defaultNavigationOwner.forEach((defaultNavItem) => {
                     if ( defaultNavItem.id === horizontalNavItem.id )
                     {
                         horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                     }
                 });
             });
 
             // Return the response
             return [
                 200,
                 {
                     compact   : cloneDeep(this._compactNavigationOwner),
                     default   : cloneDeep(this._defaultNavigationOwner),
                     futuristic: cloneDeep(this._futuristicNavigationOwner),
                     horizontal: cloneDeep(this._horizontalNavigationOwner)
                 }
             ];
         });
         // -----------------------------------------------------------------------------------------------------
         // @ Navigation - GET
         // -----------------------------------------------------------------------------------------------------
         
     }

      /**
     * Register Mock API handlers for editors
     */
      registerHandlersForEditors(): void
      {
  
          // Mark for check
          this._fuseMockApiService
          .onGet('api/common/navigation/editors')
          .reply(() => {
  
              // Fill compact navigation children using the default navigation
              this._compactNavigationEditor.forEach((compactNavItem) => {
                  this._compactNavigationEditor.forEach((defaultNavItem) => {
                      if ( defaultNavItem.id === compactNavItem.id )
                      {
                          compactNavItem.children = cloneDeep(defaultNavItem.children);
                      }
                  });
              });
  
              // Fill futuristic navigation children using the default navigation
              this._futuristicNavigationEditor.forEach((futuristicNavItem) => {
                  this._futuristicNavigationEditor.forEach((defaultNavItem) => {
                      if ( defaultNavItem.id === futuristicNavItem.id )
                      {
                          futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                      }
                  });
              });
  
              // Fill horizontal navigation children using the default navigation
              this._horizontalNavigationEditor.forEach((horizontalNavItem) => {
                  this._horizontalNavigationEditor.forEach((defaultNavItem) => {
                      if ( defaultNavItem.id === horizontalNavItem.id )
                      {
                          horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                      }
                  });
              });
  
              // Return the response
              return [
                  200,
                  {
                      compact   : cloneDeep(this._compactNavigationEditor),
                      default   : cloneDeep(this._defaultNavigationEditor),
                      futuristic: cloneDeep(this._futuristicNavigationEditor),
                      horizontal: cloneDeep(this._horizontalNavigationEditor)
                  }
              ];
          });
          // -----------------------------------------------------------------------------------------------------
          // @ Navigation - GET
          // -----------------------------------------------------------------------------------------------------
          
      }
}
