import { DateFormatter } from './../../../base/date-formatter';

import { Mapper } from 'app/data/base/mapper';
import { UserModel } from 'app/data/domain/models/user.model';
import { UserEntity } from '../entities/user-entity';



export const convertToSentence = (myString: string) => {
    // var rg = /(^\w{1}|\.\s*\w{1})/gi;
    // const newString = myString.trimStart().trimEnd().replace(rg, function (toReplace) {
    //     return toReplace.toUpperCase();
    // });

    const str = myString;

    //split the above string into an array of strings 
    //whenever a blank space is encountered

    const arr = str.split(" ");

    //loop through each element of the array and capitalize the first letter.


    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }

    //Join all the elements of the array back into a string 
    //using a blankspace as a separator 
    const str2 = arr.join(" ");

    return str2;
}

export class UserImplementationRepositoryMapper extends Mapper<UserEntity, UserModel> {
    mapFrom(param: UserEntity): UserModel {
        return {
            id: param.id,
            fullName: convertToSentence(param.full_name),
            secondLastName: convertToSentence(param.second_last_name),
            lastName: convertToSentence(param.last_name),
            email: param.email,
            createdAt: DateFormatter.dateFirebaseFormater(param.created_at),
            updatedAt: DateFormatter.dateFirebaseFormater(param.updated_at),
            dateOfBirth: DateFormatter.dateFirebaseFormater(param.date_of_birth),
            idRole: param.id_role,
            avatar: 'assets/images/avatars/male-01.jpg',
            status: param.status,
            origin: param.origin,
            initialBalance: param.initial_balance,
            successorOf: param.successor_of,
            boughtTo: param.bought_to,
        };
    }
    mapTo(param: UserModel): UserEntity {
        return {
            id: param.id,
            full_name: param.fullName,
            last_name: param.secondLastName,
            second_last_name: param.lastName,
            email: param.email,
            created_at: param.createdAt,
            updated_at: param.updatedAt,
            id_role: param.idRole,
            date_of_birth: param.dateOfBirth,
            status: param.status,
            origin: param.origin,
            initial_balance: param.initialBalance,
            successor_of: param.successorOf,
            bought_to: param.boughtTo,
        }
    }
}