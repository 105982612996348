import { User } from './../user/user.types';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap, takeUntil, Subject } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private _userService: UserService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Promise<boolean>
    {

        //console.log('USER ON NAVIGATION SERVICE');

        return new Promise((resolve, reject) => { 
            this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                //console.log('userr on service', user)
                if (user.idRole === 3) {
                    this._httpClient.get<Navigation>('api/common/navigation/owners').pipe(
                        tap((navigation) => {
                            this._navigation.next(navigation);
                        })
                    ).subscribe((navigation) => {

                        return resolve(true); 
                    })
                   
                }
                else if (user.idRole === 2) {
                    this._httpClient.get<Navigation>('api/common/navigation/editors').pipe(
                        tap((navigation) => {
                            this._navigation.next(navigation);
                        })
                    ).subscribe((navigation) => {

                        return resolve(true); 
                    })
                   
                } 
                else {
                    const prom1 = this._httpClient.get<Navigation>('api/common/navigation').pipe(
                        tap((navigation) => {
                            this._navigation.next(navigation);
                        })
                    ).subscribe((navigation) => {
                        return resolve(true); 

                    })
                }
                // Mark for check
            });
         })

    }

     /**
     * On destroy
     */
     ngOnDestroy(): void
     {
         // Unsubscribe from all subscriptions
         this._unsubscribeAll.next(null);
         this._unsubscribeAll.complete();
     }
}
