import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'noPossessor',
    pure: false
})
export class NoPossessorPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        return items.filter(x => x.origin !== filter);
        // return items.filter(item => item.title.indexOf(filter.title) !== -1);
    }
}