import { UserImplementationRepositoryMapper } from './mappers/user-repository.mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserEntity } from './entities/user-entity';
import { Injectable } from '@angular/core';
import { UserModel } from 'app/data/domain/models/user.model';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { DocImplementationRepositoryMapper } from 'app/data/base/doc-mapper';
import { User } from 'app/core/user/user.types';
import { LogService } from 'app/core/logs/log.service';

@Injectable({
    providedIn: 'root',
})
export class UserRepository {
    userMapper = new UserImplementationRepositoryMapper();
    docMapper = new DocImplementationRepositoryMapper();
    COLLECTION_NAME = 'users';

    constructor(
        private afs: AngularFirestore,
        private _logService: LogService,
    ) {
    }

    getUsers() {
        const users = this.afs.collection<UserEntity[]>(this.COLLECTION_NAME).snapshotChanges()
            .pipe(
                map(actions => actions.map(a => {
                    const data = a.payload.doc.data() as any;
                    const id = a.payload.doc.id;
                    const doc = this.docMapper.mapDoc(a.payload.doc);
                    const doc2 = this.userMapper.mapFrom(doc)
                    const doc3 = this.docMapper.mapDocUserToUser(doc2)
                    return doc3;
                }))
            )

        return users;
    }

    getPeoples() {
        const users = this.afs.collection<UserEntity[]>(this.COLLECTION_NAME, ref => ref.where('id_role', '==', 3).orderBy('full_name')).snapshotChanges()
            .pipe(
                map(actions => actions.map(a => {
                    const data = a.payload.doc.data() as any;
                    const id = a.payload.doc.id;
                    const doc = this.docMapper.mapDoc(a.payload.doc);
                    //console.log('DOC1', doc);
                    const doc2 = this.userMapper.mapFrom(doc)
                    //console.log('DOC2', doc2);

                    const doc3 = this.docMapper.mapDocUserToUser(doc2)
                    //console.log('doc3', doc3);

                    return doc3;
                }))
            )

        return users;
    }

    updateUserById(id: string, user: UserEntity): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.afs.doc<UserEntity>('users/' + id).ref.update(user).then((result) => {
                const payload = {
                    idUser: id,
                    user
                };
                this._logService.createLog('UPDATE', this.COLLECTION_NAME.toString(), payload);
                return resolve(true);
            })
            .catch((err) => {
                return reject(false);
            })
            ;
        });
    }

    updatePeoplById(id: string, user: UserEntity): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.afs.doc<UserEntity>('users/' + id).ref.update(user).then((result) => {
                const payload = {
                    idUser: id,
                    user
                };
                this._logService.createLog('UPDATE', 'peoples', payload);
                return resolve(true);
            })
            .catch((err) => {
                return reject(false);
            })
            ;
        });
    }

    getTotalCountUsers(): Promise<number> {
        return new Promise((resolve, reject) => {
            this.afs.collection<UserEntity[]>(this.COLLECTION_NAME).ref.get().then(
                (users) => {
                    //console.log('Total count', users.size)
                    resolve(users.size)
                }
            )
        })
    }

    getTotalCountPeoples(): Promise<number> {
        return new Promise((resolve, reject) => {
            this.afs.collection<UserEntity[]>(this.COLLECTION_NAME).ref.where('id_role', '==', 3).get().then(
                (users) => {
                    //console.log('Total count', users.size)
                    resolve(users.size)
                }
            )
        })
    }

    getUsersPagination(limit: number, orderBy: string) {
        const users = this.afs.collection<UserEntity[]>(this.COLLECTION_NAME,
            ref => ref.limit(limit).orderBy(orderBy)
        ).snapshotChanges()
        return users;
    }

    getPrevUsersPagination(limit: number, orderBy: string, startAt: any, endBefore: any) {

        //console.log('Startat', startAt);
        const users = this.afs.collection<UserEntity[]>(this.COLLECTION_NAME,
            ref => ref
            .limit(limit)
            .orderBy(orderBy)
            .startAt(startAt)
            .endBefore(endBefore)
        ).snapshotChanges();

        return users;
    }

    getNextUsersPagination(limit: number, orderBy: string, startAfter: any) {
        const users = this.afs.collection<UserEntity[]>(this.COLLECTION_NAME,
            ref => ref
            .limit(limit)
            .orderBy(orderBy)
            .startAfter(startAfter)
        ).snapshotChanges();

        return users;
    }


    getUserById(id: string) {
        return this.afs.doc<UserEntity>('users/' + id)
            .get().pipe(map(this.docMapper.mapDoc), map(this.userMapper.mapFrom), map(this.docMapper.mapDocUserToUser))
        // .snapshotChanges()
        // .pipe(map(this.docMapper.mapDoc), map(this.userMapper.mapFrom), map(this.docMapper.mapDocUserToUser));
    }

    getUserByIdStatic(id: string) {
        return this.afs.doc<UserEntity>('users/' + id).ref.get();
        // .snapshotChanges()
        // .pipe(map(this.docMapper.mapDoc), map(this.userMapper.mapFrom), map(this.docMapper.mapDocUserToUser));
    }

    createUser(user: UserEntity) {
        return new Promise((resolve, reject) => {
            this.afs.collection(this.COLLECTION_NAME.toString()).add(user)
                .then((us) => {
                    //console.log('US', us);
                    const payload = {
                        userId: us.id,
                        user
                    };

                    this._logService.createLog('CREATE', this.COLLECTION_NAME.toString(), payload);
                    resolve(us)
                }).catch((err) => {
                    //console.log('err', err);
                    reject('Ocurrio un error al crear el usuario');
                })
        });
    }

    getUserWithNames(name, lastName, secondLastName) {
        return this.afs.collection(this.COLLECTION_NAME.toString()).ref.where('full_name', '==', `${name}`)
            .where('last_name', '==', `${lastName}`)
            .where('second_last_name', '==', `${secondLastName}`)
            .get();
    }

    getUserWithEmail(email) {
        return this.afs.collection(this.COLLECTION_NAME.toString())
            .ref.where('email', '==', `${email}`).get();
    }

    // login(params: {username: string, password: string}): Observable<UserModel> {
    //     return this.http
    //         .post<UserEntity>('https://example.com/login', {params})
    //         .pipe(map(this.userMapper.mapFrom));
    // }
    // register(params: {phoneNum: string, password: string}): Observable<UserModel> {
    //    return this.http
    //         .post<UserEntity>('https://example.com/register', {params})
    //         .pipe(map(this.userMapper.mapFrom));
    // }
    // getUserProfile(): Observable<UserModel>{
    //     return this.http.get<UserEntity>('https://example.com/user').pipe(
    //         map(this.userMapper.mapFrom));
    // }
}