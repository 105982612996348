import { UserService } from 'app/core/user/user.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, finalize } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { UserRepository } from 'app/data/repositories/user/user.repository';
import { UserModel } from 'app/data/domain/models/user.model';
import { DocImplementationRepositoryMapper } from 'app/data/base/doc-mapper';
import { UserImplementationRepositoryMapper } from 'app/data/repositories/user/mappers/user-repository.mapper';
import { environment } from 'environments/environment';
import { UserEntity } from 'app/data/repositories/user/entities/user-entity';
import { FileUpload } from './assignments.types';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AssignmentRepository } from 'app/data/repositories/assignment/assignment.repository';
import { AssignmentEntity } from 'app/data/repositories/assignment/entities/assignment-entity';

@Injectable({
    providedIn: 'root',
})
export class AssignmentsService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    userMapper = new UserImplementationRepositoryMapper();
    docMapper = new DocImplementationRepositoryMapper();

    private basePath = '/attachments/assignments';
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        public auth: AngularFireAuth,
        private _userService: UserService,
        private _assignmentsRepository: AssignmentRepository,
        private storage: AngularFireStorage
    ) { }



    getAssignments(): Observable<AssignmentEntity[]> {
        return this._assignmentsRepository.getAssignments();
    }

    async getAssignmentById(id: string): Promise<AssignmentEntity> {
        let prom1: any = await this._assignmentsRepository.getAssingmentByIdStatic(id);

        const d: any = prom1.data();
        const doc = {
            id: prom1.id,
            ...d
        }
        return doc;
    }

    pushFileToStorage(
        fileUpload: FileUpload,
        invoice
    ): Promise<{ name: string; url: string }> {
        //console.log('FILE Upload: ', fileUpload);
        return new Promise((resolve, reject) => {
            const namedFile = `${invoice}-${new Date().getTime()}`;
            const filePath = `${this.basePath}/${namedFile}`;
            const storageRef = this.storage.ref(filePath);
            const uploadTask = this.storage.upload(filePath, fileUpload);

            uploadTask
                .snapshotChanges()
                .pipe(
                    finalize(() => {
                        storageRef.getDownloadURL().subscribe((downloadURL) => {
                            // fileUpload.url = downloadURL;
                            // fileUpload.name = fileUpload.file.name;
                            //console.log('downloadURL', downloadURL);
                            resolve({ name: namedFile, url: downloadURL });
                            // this.saveFileData(fileUpload);
                        });
                    })
                )
                .subscribe();

            uploadTask.percentageChanges().subscribe((percentage: any) => {
                // //console.log('PERCENTAJE', percentage)
                percentage = Math.round(percentage);
                // //console.log(percentage);
                if (percentage === 100) {
                    // //console.log('listo');
                }
            });
        });
    }

    createCession(form, files) {
        return new Promise(async (resolve, reject) => {
            // Validando si el usuario cuenta con hectareas disponibles para ceder
            const dataUser = await this._userService.getUserById(form.assignor);
            // const saldoAvailable = dataUser.initialBalance;
            // const selles =
            //     await this._assignmentsRepository.getAssignmentsByAssignorIdStatic(
            //         dataUser.id
            //     );
            // const buys =
            //     await this._assignmentsRepository.getAssignmentsByAssigneeIdStatic(
            //         dataUser.id
            //     );

            //console.log('selles', selles);
            //console.log('selles', buys);
            //console.log('Data user', dataUser);

            // const mtsBuyed = Number(await this.getMtsBuyed(buys));
            // const mtsSells = Number(await this.getMtsSells(selles));

            // const initialHectaresInMts = saldoAvailable * 10000;
            // const sumTotalOfAvailableAndBuy = initialHectaresInMts + mtsBuyed ;
            // const totalmtsAvailable = sumTotalOfAvailableAndBuy - mtsSells;

            // if (totalmtsAvailable < Number(form.meters)) {
            //     return reject({
            //         message:
            //             'No se puede completar el registro debido a que el Cesionario no cuenta con las hectáreas disponibles para ceder',
            //     });
            // }

            const invoice =
                await this._assignmentsRepository.getInvoiceNumber();
            //console.log('INVOCIE', invoice);

            //Subiendo archivos
            const responseUrls = await this.uploadFilesToFirestorage(
                files,
                invoice
            );
            //console.log('RESPONSE IURS', responseUrls);

            // Creando modelo para completar proceso

            const model: AssignmentEntity = {
                files: responseUrls,
                invoiceNumber: invoice,
                assignorId: form.assignor,
                assigneeId: form.assignee,
                agriculturalQualityOfAssignee: Number(
                    form.agriculturalQualityOfAssignee
                ),
                agriculturalQualityOfAssignor: Number(
                    form.agriculturalQualityOfAssignor
                ),
                administration: form.administration,
                meters: form.meters,
                observations: form.observations,
                expeditionDate: new Date(form.expeditionDate),
                created_at: new Date(),
                updated_at: new Date(),
                location: form.location,
            };

            try {
                this._assignmentsRepository
                    .createAssignment(model)
                    .then((response) => {
                        //console.log('Data updated RESPONSE', response);
                        return resolve(response);
                    })
                    .catch((err) => {
                        return reject({ err: err, message: err });
                    });
            } catch (error) {
                return reject({ err: error, message: 'Ocurrio un error al procesar la solicitud' });
            }


        });
        // Validate if user have hectares
    }

    async getTotalSellsByIdUser(idUser: string) {
        const selles = await this._assignmentsRepository.getAssignmentsByAssignorIdStatic(
            idUser
        );

        const mtsSells = await this.getMtsSells(selles);
        //console.log('mtsSells', mtsSells);
        const total = mtsSells;
        return total.toFixed(2);
    }

    async getTotalBuyedByIdUser(idUser: string) {
        const buys =
            await this._assignmentsRepository.getAssignmentsByAssigneeIdStatic(
                idUser
            );

        const mtsBuyed = await this.getMtsBuyed(buys);
        //console.log('mtsBuyed', mtsBuyed);

        const total = mtsBuyed;
        return total.toFixed(2);
    }

    async uploadFilesToFirestorage(files: any[], invoice) {
        let filesData = [];
        for (const iterator of files) {
            const responseData = await this.pushFileToStorage(
                iterator.file,
                invoice
            );
            filesData.push(responseData);
        }

        return filesData;
    }

    getMtsBuyed(buys) {
        let mtsBuyed = 0;
        for (let index = 0; index < buys.length; index++) {
            const element = buys[index];
            mtsBuyed = mtsBuyed + element.meters;
        }

        return mtsBuyed;
    }

    getMtsSells(buys) {
        let mtsBuyed = 0;
        if (buys.length === 0) {
            return 0;
        }
        for (let index = 0; index < buys.length; index++) {
            const element = buys[index];
            mtsBuyed = mtsBuyed + element.meters;
        }

        return mtsBuyed;
    }
}
