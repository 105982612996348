import { Router } from '@angular/router';
import { map, BehaviorSubject } from 'rxjs';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import {
  DocAssignmentImplementationRepositoryMapper,
  DocImplementationRepositoryMapper,
} from 'app/data/base/doc-mapper';
import { UserImplementationRepositoryMapper } from 'app/data/repositories/user/mappers/user-repository.mapper';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Swal from 'sweetalert2';
import { AssignmentsService } from 'app/core/assignments/assignments.service';
import { DateFormatter } from 'app/data/base/date-formatter';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-assignment-table',
  templateUrl: './assignment-table.component.html',
})
export class AssignmentTableComponent {
  //Models for Input fields
  nameValue: string;
  placeValue: string;

  //Data object for listing items
  tableData: any[] = [];

  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;
  totalItems = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;
  userMapper = new UserImplementationRepositoryMapper();
  docMapper = new DocImplementationRepositoryMapper();
  docAssignmentMapper = new DocAssignmentImplementationRepositoryMapper();

  itemsPerPage = 20;
  totalPages = 1;

  categories = [
    {
      title: 'Folio',
      slug: 'invoiceNumber'
    },
  ];

  filters: {
    categorySlug$: BehaviorSubject<string>;
    query$: BehaviorSubject<string>;
    hideCompleted$: BehaviorSubject<boolean>;
  } = {
      categorySlug$: new BehaviorSubject('all'),
      query$: new BehaviorSubject(''),
      hideCompleted$: new BehaviorSubject(false)
    };


  holderText = 'Buscar por';
  selectedFilter = '';
  searchTerm = 0;

  COLLECTION_NAME = 'assignments';

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private firestore: AngularFirestore,
    private _userService: UserService,
    private router: Router,
    private _assignmentsService: AssignmentsService
  ) { }

  async ngOnInit() {
    this.holderText = 'Buscar por ' + this.categories[0].title;
    this.selectedFilter = this.categories[0].slug;
    const items = await this.getTotalItemConsoult();
    this.totalItems = items.size;
    this.loadItems();
  }

  getTotalItemConsoult() {
    if (this.searchTerm > 0) {
      return this.firestore.collection(this.COLLECTION_NAME.toString()).ref
        .where(this.selectedFilter, '==', this.searchTerm).get();
    } else {
      return this.firestore.collection(this.COLLECTION_NAME.toString()).ref.get();
    }
  }

  getFirstConsoult() {

    if (this.searchTerm > 0) {
      return this.firestore.collection(this.COLLECTION_NAME.toString(),
        (ref) =>
          ref
            .where(this.selectedFilter, '==', this.searchTerm)
            .limit(this.itemsPerPage)
      )

    } else {
      return this.firestore
        .collection(this.COLLECTION_NAME.toString(), (ref) =>
          ref.orderBy('created_at', 'desc').limit(this.itemsPerPage)
        )
    }
  }

  getPrevConsoult() {
    if (this.searchTerm > 0) {
      return this.firestore.collection(this.COLLECTION_NAME.toString(),
        (ref) =>
          ref
            .where(this.selectedFilter, '==', this.searchTerm)
            .startAt(this.get_prev_startAt())
            .endBefore(this.firstInResponse)
            .limit(this.itemsPerPage)
      )

    }
    return this.firestore
      .collection(this.COLLECTION_NAME.toString(), (ref) =>
        ref
          .orderBy('created_at', 'desc')
          .startAt(this.get_prev_startAt())
          .endBefore(this.firstInResponse)
          .limit(this.itemsPerPage)
      )
  }

  getNextConsult() {

    if (this.searchTerm > 0) {
      return this.firestore
      .collection(this.COLLECTION_NAME.toString(), (ref) =>
        ref
          .where(this.selectedFilter, '==', this.searchTerm)
          .limit(this.itemsPerPage)
          .startAfter(this.lastInResponse)
      );

    }
    return this.firestore
      .collection(this.COLLECTION_NAME.toString(), (ref) =>
        ref
          .orderBy('created_at', 'desc')
          .limit(this.itemsPerPage)
          .startAfter(this.lastInResponse)
      );
  }

  onClickEditButton(id) {

    this.router.navigateByUrl('/assignments/edit/' + id);

  }

  onClickValidEditButton(id) {

    this.router.navigateByUrl('/assignments/edit-enable/' + id + '/' + true);

  }

  onClickListSubdivisions(id) {

    this.router.navigateByUrl('/assignments/subdivisions/' + id);

  }


  /**
   * Filter by category
   *
   * @param change
   */
  filterByCategory(change: MatSelectChange): void {
    // this.filters.categorySlug$.next(change.value);
    this.selectedFilter = change.value;
  }


  /**
 * Track by function for ngFor loops
 *
 * @param index
 * @param item
 */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
  /**
    * Filter by search query
    *
    * @param query
    */
  filterByQuery(query: string): void {
    // this.filters.query$.next(query);
    //console.log('DATa', query);
    const newValue = query.split('-');
    //console.log('new Value', newValue);
    let value = '';
    for (const iterator of newValue) {
      value = value + iterator;
    }

    this.searchTerm = Number(value)
    //console.log('value string', value);
    //console.log('Value for number', Number(value));
    //console.log('Value for slug', this.selectedFilter);
    this.ngOnInit();
    //console.log('Ejecutando consulta');

  }


  /**
   * Show/hide completed courses
   *
   * @param change
   */
  toggleCompleted(change: MatSlideToggleChange): void {
    this.filters.hideCompleted$.next(change.checked);
  }


  getFolio(invoiceNumber) {
    const folio = invoiceNumber.toString().padStart(8, '0');
    const newfolio = folio.split("");
    const firstPart = '' + newfolio[0] + newfolio[1] + newfolio[2] + newfolio[3];
    const secondPart = '' + newfolio[4] + newfolio[5] + newfolio[6] + newfolio[7];

    return firstPart + '-' + secondPart;
  }

  loadItems() {
    //console.log('ID ROLE', 3);
    this.getFirstConsoult()
      .snapshotChanges()
      .subscribe(
        async (response) => {
          if (!response.length) {
            this.tableData = [];
            //console.log('No Data Available');
            return false;
          }
          //console.log('RESPONSE', response);
          this.firstInResponse = response[0].payload.doc;
          this.lastInResponse =
            response[response.length - 1].payload.doc;

          const newtableData = [];

          for (let item of response) {
            const docAssignment = this.docAssignmentMapper.mapDoc(
              item.payload.doc
            );

            const getUserAssigneeId =
              await this._userService.getUserById(
                docAssignment.assigneeId
              );
            const getUserAssignorId =
              await this._userService.getUserById(
                docAssignment.assignorId
              );

            const ht = docAssignment.meters / 10000; //
            newtableData.push({
              assignee: getUserAssigneeId,
              assignor: getUserAssignorId,
              ht: ht.toFixed(2),
              date: DateFormatter.dateFirebaseFormater(
                docAssignment.expeditionDate
              ),
              ...docAssignment,
            });
          }

          this.tableData = newtableData;
          //console.log(this.tableData);

          //Initialize values
          this.prev_strt_at = [];
          this.pagination_clicked_count = 0;
          this.disable_next = false;
          this.disable_prev = false;

          const pageLeng = this.pagination_clicked_count + 1;
          const itemsLenght = this.tableData.length * pageLeng;
          //console.log('this.tableData.length', itemsLenght);
          //console.log('this.totalItems.length', this.totalItems);

          if (itemsLenght === this.totalItems) {
            this.disable_next = true;
          }

          this.totalPages = Math.ceil(
            this.totalItems / this.itemsPerPage
          );

          //Push first item to use for Previous action
          this.push_prev_startAt(this.firstInResponse);
        },
        (error) => {
          //console.log('HOLA');
        }
      );
  }

  //Show previous set
  prevPage() {
    this.disable_prev = true;
    this.getPrevConsoult()
      .get()
      .subscribe(
        async (response) => {
          this.firstInResponse = response.docs[0];
          this.lastInResponse =
            response.docs[response.docs.length - 1];

          const newtableData = [];
          for (let item of response.docs) {
            const docAssignment = this.docAssignmentMapper.mapDoc(
              item
            );

            const getUserAssigneeId =
              await this._userService.getUserById(
                docAssignment.assigneeId
              );
            const getUserAssignorId =
              await this._userService.getUserById(
                docAssignment.assignorId
              );

            const ht = docAssignment.meters / 10000; //
            newtableData.push({
              assignee: getUserAssigneeId,
              assignor: getUserAssignorId,
              ht: ht.toFixed(2),
              date: DateFormatter.dateFirebaseFormater(
                docAssignment.expeditionDate
              ),
              ...docAssignment,
            });
          }

          this.tableData = newtableData;
          //console.log(this.tableData);

          //Maintaing page no.
          this.pagination_clicked_count--;

          //Pop not required value in array
          this.pop_prev_startAt(this.firstInResponse);

          //Enable buttons again
          this.disable_prev = false;
          this.disable_next = false;
        },
        (error) => {
          this.disable_prev = false;
        }
      );
  }

  nextPage() {
    this.disable_next = true;

    this.getNextConsult().get()
      .subscribe(
        async (response) => {
          if (!response.docs.length) {
            this.disable_next = true;
            return;
          }

          this.firstInResponse = response.docs[0];

          this.lastInResponse =
            response.docs[response.docs.length - 1];
          const newtableData = [];
          for (let item of response.docs) {
            const docAssignment = this.docAssignmentMapper.mapDoc(
              item
            );

            const getUserAssigneeId =
              await this._userService.getUserById(
                docAssignment.assigneeId
              );
            const getUserAssignorId =
              await this._userService.getUserById(
                docAssignment.assignorId
              );

            const ht = docAssignment.meters / 10000; //
            newtableData.push({
              assignee: getUserAssigneeId,
              assignor: getUserAssignorId,
              ht: ht.toFixed(2),
              date: DateFormatter.dateFirebaseFormater(
                docAssignment.expeditionDate
              ),
              ...docAssignment,
            });
          }
          this.tableData = newtableData;
          //console.log(this.tableData);

          this.pagination_clicked_count++;

          this.push_prev_startAt(this.firstInResponse);

          this.disable_next = false;
        },
        (error) => {
          this.disable_next = false;
        }
      );
  }

  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }

  //Remove not required document
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach((element) => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }

  //Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > this.pagination_clicked_count + 1)
      this.prev_strt_at.splice(
        this.prev_strt_at.length - 2,
        this.prev_strt_at.length - 1
      );
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }

  //Date formate
  readableDate(time) {
    var d = new Date(time);
    return d.getDate() + '/' + d.getMonth() + '/' + d.getFullYear();
  }
}
