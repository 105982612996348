import { Router } from '@angular/router';
import { map } from 'rxjs';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import {
  DocAssignmentImplementationRepositoryMapper,
  DocImplementationRepositoryMapper,
} from 'app/data/base/doc-mapper';
import { UserImplementationRepositoryMapper } from 'app/data/repositories/user/mappers/user-repository.mapper';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Swal from 'sweetalert2';
import { AssignmentsService } from 'app/core/assignments/assignments.service';
import { DateFormatter } from 'app/data/base/date-formatter';


@Component({
  selector: 'app-assignment-people-selled',
  templateUrl: './assignment-people-selled.component.html',
})
export class AssignmentPeopleSelledComponent {

  @Input()
  idUser: string = '';

  //Models for Input fields
  nameValue: string;
  placeValue: string;

  //Data object for listing items
  tableData: any[] = [];

  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;
  totalItems = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;
  userMapper = new UserImplementationRepositoryMapper();
  docMapper = new DocImplementationRepositoryMapper();
  docAssignmentMapper = new DocAssignmentImplementationRepositoryMapper();

  itemsPerPage = 20;
  totalPages = 1;


  // @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private firestore: AngularFirestore,
    private _userService: UserService,
    private router: Router,
    private _assignmentsService: AssignmentsService
  ) { }

  async ngOnInit() {
    //console.log('LOAD')
    const items = await this.getTotalItemConsoult();
    //console.log('ITEMS: ', items);
    this.totalItems = items.size;
    this.loadItems();
  }

  getTotalItemConsoult() {
    return this.firestore.collection('assignments').ref.where('assignorId', '==', this.idUser).get()
  }

  getFirstConsoult() {
    return this.firestore
      .collection('assignments', (ref) =>
        ref.where('assignorId', '==', this.idUser)
          .orderBy('created_at', 'desc').limit(this.itemsPerPage)
      )
  }

  getPrevConsoult() {
    return this.firestore
      .collection('assignments', (ref) =>
        ref
          .where('assignorId', '==', this.idUser)
          .orderBy('created_at', 'desc')
          .startAt(this.get_prev_startAt())
          .endBefore(this.firstInResponse)
          .limit(this.itemsPerPage)
      )
  }

  getNextConsult() {
    return this.firestore
      .collection('assignments', (ref) =>
        ref
          .where('assignorId', '==', this.idUser)
          .orderBy('created_at', 'desc')
          .limit(this.itemsPerPage)
          .startAfter(this.lastInResponse)
      )
  }

  onClickEditButton(id) {

    this.router.navigateByUrl('/assignments/edit/' + id);

  }

  onClickListSubdivisions(id) {

    this.router.navigateByUrl('/assignments/subdivisions/' + id);

  }

  getFolio(invoiceNumber) {
    const folio = invoiceNumber.toString().padStart(8, '0');
    const newfolio = folio.split("");
    const firstPart = '' + newfolio[0] + newfolio[1] + newfolio[2] + newfolio[3];
    const secondPart = '' + newfolio[4] + newfolio[5] + newfolio[6] + newfolio[7];

    return firstPart + '-' + secondPart;
  }

  loadItems() {
    //console.log('ID ROLE', 3);
    this.getFirstConsoult()
      .snapshotChanges()
      .subscribe(
        async (response) => {
          if (!response.length) {
            //console.log('No Data Available');
            return false;
          }
          //console.log('RESPONSE', response);
          this.firstInResponse = response[0].payload.doc;
          this.lastInResponse =
            response[response.length - 1].payload.doc;

          const newtableData = [];

          for (let item of response) {
            const docAssignment = this.docAssignmentMapper.mapDoc(
              item.payload.doc
            );

            const getUserAssigneeId =
              await this._userService.getUserById(
                docAssignment.assigneeId
              );
            const getUserAssignorId =
              await this._userService.getUserById(
                docAssignment.assignorId
              );

            const ht = docAssignment.meters / 10000; //
            newtableData.push({
              assignee: getUserAssigneeId,
              assignor: getUserAssignorId,
              ht: ht.toFixed(2),
              date: DateFormatter.dateFirebaseFormater(
                docAssignment.expeditionDate
              ),
              ...docAssignment,
            });
          }

          this.tableData = newtableData;
          //console.log(this.tableData);

          //Initialize values
          this.prev_strt_at = [];
          this.pagination_clicked_count = 0;
          this.disable_next = false;
          this.disable_prev = false;

          const pageLeng = this.pagination_clicked_count + 1;
          const itemsLenght = this.tableData.length * pageLeng;
          //console.log('this.tableData.length', itemsLenght);
          //console.log('this.totalItems.length', this.totalItems);

          if (itemsLenght === this.totalItems) {
            this.disable_next = true;
          }

          this.totalPages = Math.ceil(
            this.totalItems / this.itemsPerPage
          );

          //Push first item to use for Previous action
          this.push_prev_startAt(this.firstInResponse);
        },
        (error) => {
          //console.log('HOLA');
        }
      );
  }

  //Show previous set
  prevPage() {
    this.disable_prev = true;
    this.getPrevConsoult()
      .get()
      .subscribe(
        async (response) => {
          this.firstInResponse = response.docs[0];
          this.lastInResponse =
            response.docs[response.docs.length - 1];

          const newtableData = [];
          for (let item of response.docs) {
            const docAssignment = this.docAssignmentMapper.mapDoc(
              item
            );

            const getUserAssigneeId =
              await this._userService.getUserById(
                docAssignment.assigneeId
              );
            const getUserAssignorId =
              await this._userService.getUserById(
                docAssignment.assignorId
              );

            const ht = docAssignment.meters / 10000; //
            newtableData.push({
              assignee: getUserAssigneeId,
              assignor: getUserAssignorId,
              ht: ht.toFixed(2),
              date: DateFormatter.dateFirebaseFormater(
                docAssignment.expeditionDate
              ),
              ...docAssignment,
            });
          }

          this.tableData = newtableData;
          //console.log(this.tableData);

          //Maintaing page no.
          this.pagination_clicked_count--;

          //Pop not required value in array
          this.pop_prev_startAt(this.firstInResponse);

          //Enable buttons again
          this.disable_prev = false;
          this.disable_next = false;
        },
        (error) => {
          this.disable_prev = false;
        }
      );
  }

  nextPage() {
    this.disable_next = true;
    this.getNextConsult()
      .get()
      .subscribe(
        async (response) => {
          if (!response.docs.length) {
            this.disable_next = true;
            return;
          }

          this.firstInResponse = response.docs[0];

          this.lastInResponse =
            response.docs[response.docs.length - 1];
          const newtableData = [];
          for (let item of response.docs) {
            const docAssignment = this.docAssignmentMapper.mapDoc(
              item
            );

            const getUserAssigneeId =
              await this._userService.getUserById(
                docAssignment.assigneeId
              );
            const getUserAssignorId =
              await this._userService.getUserById(
                docAssignment.assignorId
              );

            const ht = docAssignment.meters / 10000; //
            newtableData.push({
              assignee: getUserAssigneeId,
              assignor: getUserAssignorId,
              ht: ht.toFixed(2),
              date: DateFormatter.dateFirebaseFormater(
                docAssignment.expeditionDate
              ),
              ...docAssignment,
            });
          }
          this.tableData = newtableData;
          //console.log(this.tableData);

          this.pagination_clicked_count++;

          this.push_prev_startAt(this.firstInResponse);

          this.disable_next = false;
        },
        (error) => {
          this.disable_next = false;
        }
      );
  }

  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }

  //Remove not required document
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach((element) => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }

  //Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > this.pagination_clicked_count + 1)
      this.prev_strt_at.splice(
        this.prev_strt_at.length - 2,
        this.prev_strt_at.length - 1
      );
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }

  //Date formate
  readableDate(time) {
    var d = new Date(time);
    return d.getDate() + '/' + d.getMonth() + '/' + d.getFullYear();
  }
}
