export const ERROR_MESSAGES = {
    name: [
      { type: 'required', message: 'El campo Nombre(s) es obligatorio' },
    ],
    last_name: [
      { type: 'required', message: 'Los apellidos son necesarios' },
      { type: 'minlength', message: 'El mínimo de caracteres para los apellidos es de 3' },
      { type: 'maxlength', message: 'El máximo de caracteres para los apellidos es de 50' },
      { type: 'pattern', message: 'Ingresa apellidos validos' },
    ],
    role: [
      { type: 'required', message: 'El role es necesario' },
      { type: 'minlength', message: 'El mínimo de caracteres para el role es de 6' },
      { type: 'maxlength', message: 'El máximo de caracteres para el role es de 50' },
      { type: 'pattern', message: 'Ingresa un role valido' },
    ],
  };