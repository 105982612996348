import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { BehaviorSubject, map } from 'rxjs';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { DocImplementationRepositoryMapper } from 'app/data/base/doc-mapper';
import { UserImplementationRepositoryMapper } from 'app/data/repositories/user/mappers/user-repository.mapper';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Swal from 'sweetalert2';
import { DateFormatter } from 'app/data/base/date-formatter';
import moment from 'moment';

@Component({
  selector: 'app-logs-table',
  templateUrl: './logs-table.component.html',
})
export class LogsTableComponent {

  //Models for Input fields
  nameValue: string;
  placeValue: string;

  //Data object for listing items
  tableData: User[] = [];

  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;
  totalItems = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;
  userMapper = new UserImplementationRepositoryMapper();
  docMapper = new DocImplementationRepositoryMapper();
  itemsPerPage = 20;
  totalPages = 1;

  COLLECTION_NAME = 'logs';
  categories = [
    {
      title: 'Id de usuario',
      slug: 'idUser'
    },
    {
      title: 'Sección',
      slug: 'table'
    },
    {
      title: 'Fecha',
      slug: 'date'
    }
  ];

  filters: {
    categorySlug$: BehaviorSubject<string>;
    query$: BehaviorSubject<string>;
    hideCompleted$: BehaviorSubject<boolean>;
  } = {
      categorySlug$: new BehaviorSubject('all'),
      query$: new BehaviorSubject(''),
      hideCompleted$: new BehaviorSubject(false)
    };


  holderText = 'Buscar por';
  selectedFilter = '';
  searchTerm = 0;
  searchStringTerm = '';
  searchDateTerm = '';


  // @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private firestore: AngularFirestore, private _userService: UserService, private router: Router) {

  }

  async ngOnInit() {
    this.selectedFilter = this.categories[0].slug;
    this.holderText = 'Buscar por ' + this.categories[0].title;
    this.loadItems();
  }

  onChangeDate($event) {
    //console.log('DATEEE', $event.target.value);
    const newDate = moment($event.target.value).format('YYYY-MM-DD');
    this.searchDateTerm = newDate;
    //console.log('newDate', newDate);
    this.loadItems();
  }

  async getTotalItemConsoult() {

    //TODO: this
    if (this.selectedFilter === 'idUser' && this.searchStringTerm) {
      //console.log('searchStringTerm entr');
      const res = this.firestore.collection(this.COLLECTION_NAME.toString())
        .ref.where(this.selectedFilter, '==', this.searchStringTerm)
        .get()
      const dd = await res;
      this.totalItems = dd.size;
    }

    if (this.selectedFilter === 'table' && this.searchStringTerm) {
      //console.log('searchStringTerm entr');
      const res = this.firestore.collection(this.COLLECTION_NAME.toString())
        .ref.where(this.selectedFilter, '==', this.searchStringTerm.toLowerCase())
        .get()
      const dd = await res;
      this.totalItems = dd.size;
    }

    if (this.selectedFilter === 'date' && this.searchDateTerm) {
      //console.log('searchStringTerm entr');
      const res = this.firestore.collection(this.COLLECTION_NAME.toString())
        .ref
        .where('date', '>', this.searchDateTerm)
        .where('date', '<', this.searchDateTerm)
        .get()
      const dd = await res;
      this.totalItems = dd.size;
    }


    else {
      const res = this.firestore
        .collection(this.COLLECTION_NAME.toString()).ref
        .get();
      const dd = await res;
      this.totalItems = dd.size;
    }
  }

  getFirstConsoult() {
    this.getTotalItemConsoult();
    if (this.selectedFilter === 'idUser' && this.searchStringTerm) {

      return this.firestore.collection(this.COLLECTION_NAME.toString(),
        (ref) =>
          ref
            .where(this.selectedFilter, '==', this.searchStringTerm)
      )
    }
    if (this.selectedFilter === 'table' && this.searchStringTerm) {

      return this.firestore.collection(this.COLLECTION_NAME.toString(),
        (ref) =>
          ref
            .where(this.selectedFilter, '==', this.searchStringTerm.toLowerCase())
      )
    }

    if (this.selectedFilter === 'date' && this.searchDateTerm) {
      //console.log('searchDateTerm', this.searchDateTerm);
      const dateMomentYes = moment(this.searchDateTerm).add(1, "days")
      const dateMomentBef = moment(this.searchDateTerm).add(2, "days");
      const dateFormatedA= dateMomentYes.format('YYYY-MM-DD');
      const dateFormatedB= dateMomentBef.format('YYYY-MM-DD')

      //console.log('dateMomentYes', dateMomentYes.format('YYYY-MM-DD'));
      //console.log('dateMomentBef', dateMomentBef.format('YYYY-MM-DD'));
      let start = new Date(dateFormatedA);
    let end = new Date( dateFormatedB);
    start.setHours(0,0,0,0);
    end.setHours(0,0,0,0);

    //console.log('start', start)
    //console.log('end', end)

      return this.firestore.collection(this.COLLECTION_NAME.toString(),
        (ref) =>
          ref
            .where('date', '>',start)
            .where('date', '<', end)
      )
    }



    return this.firestore
      .collection(this.COLLECTION_NAME.toString(), (ref) =>
        ref.limit(this.itemsPerPage)
          .orderBy('date', 'desc')
      )

  }

  loadItems() {
    this.getFirstConsoult().snapshotChanges()
      .subscribe(response => {
        if (!response.length) {
          //console.log("No Data Available");
          this.tableData = [];
          return false;
        }
        //console.log('RESPONSE', response)
        this.firstInResponse = response[0].payload.doc;
        this.lastInResponse = response[response.length - 1].payload.doc;

        const newtableData = [];
        for (let item of response) {
          const nd: any = item.payload.doc.data();
          const ni: any = {
            id: item.payload.doc.id,
            ...nd,
            date: DateFormatter.dateFirebaseFormater(nd.date)
          }

          newtableData.push(ni);
        }

        this.tableData = newtableData;

        //console.log(this.tableData);

        //Initialize values
        this.prev_strt_at = [];
        this.pagination_clicked_count = 0;
        this.disable_next = false;
        this.disable_prev = false;

        const pageLeng = this.pagination_clicked_count + 1;
        const itemsLenght = this.tableData.length * pageLeng;
        //console.log('this.tableData.length', itemsLenght)
        //console.log('this.totalItems.length', this.totalItems)


        if (itemsLenght === this.totalItems) {
          this.disable_next = true;
        }

        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage)

        //Push first item to use for Previous action
        this.push_prev_startAt(this.firstInResponse);
      }, error => {
      });
  }

  /**
 * Filter by category
 *
 * @param change
 */
  filterByCategory(change: MatSelectChange): void {
    // this.filters.categorySlug$.next(change.value);
    //console.log('Value', change.value);
    this.selectedFilter = change.value;
    const item = this.categories.find(c => c.slug === change.value)
    this.holderText = 'Buscar por ' + item.title;
  }


  /**
 * Track by function for ngFor loops
 *
 * @param index
 * @param item
 */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
  /**
    * Filter by search query
    *
    * @param query
    */
  filterByQuery(query: string): void {
    // this.filters.query$.next(query);
    //console.log('QUERY', query);
    this.searchStringTerm = query;
    this.loadItems();

  }

  onClickViewButton(id, payload) {
    this.router.navigateByUrl('/logs/view/' + id);
  }

  //Show previous set 
  prevPage() {
    this.disable_prev = true;
    this.firestore.collection('logs', ref => ref
      .orderBy('date', 'desc')
      .startAt(this.get_prev_startAt())
      .endBefore(this.firstInResponse)
      .limit(this.itemsPerPage)
    ).get()
      .subscribe(response => {
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];

        const newtableData = [];
        for (let item of response.docs) {
          const nd: any = item.data();
          const ni: any = {
            id: item.id,
            ...nd,
            date: DateFormatter.dateFirebaseFormater(nd.date)
          }

          newtableData.push(ni);
        }

        //console.log(this.tableData);

        this.tableData = newtableData;


        //Maintaing page no.
        this.pagination_clicked_count--;

        //Pop not required value in array
        this.pop_prev_startAt(this.firstInResponse);

        //Enable buttons again
        this.disable_prev = false;
        this.disable_next = false;
      }, error => {
        this.disable_prev = false;
      });
  }

  nextPage() {
    this.disable_next = true;
    this.firestore.collection('logs', ref => ref
      .limit(this.itemsPerPage)
      .orderBy('date', 'desc')
      .startAfter(this.lastInResponse)
    ).get()
      .subscribe(response => {

        if (!response.docs.length) {
          this.disable_next = true;
          return;
        }

        this.firstInResponse = response.docs[0];

        this.lastInResponse = response.docs[response.docs.length - 1];
        const newtableData = [];
        for (let item of response.docs) {
          const nd: any = item.data();
          const ni: any = {
            id: item.id,
            ...nd,
            date: DateFormatter.dateFirebaseFormater(nd.date)
          }

          newtableData.push(ni);
        }

        //console.log(this.tableData);

        this.tableData = newtableData;
        this.pagination_clicked_count++;

        this.push_prev_startAt(this.firstInResponse);

        this.disable_next = false;
      }, error => {
        this.disable_next = false;
      });
  }

  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }

  //Remove not required document 
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach(element => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }

  //Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > (this.pagination_clicked_count + 1))
      this.prev_strt_at.splice(this.prev_strt_at.length - 2, this.prev_strt_at.length - 1);
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }

  //Date formate
  readableDate(time) {
    var d = new Date(time);
    return d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear();
  }
}
