import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanMatch
{
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can match
     *
     * @param route
     * @param segments
     */
    canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this._check(segments);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param segments
     * @private
     */
    private async _check(segments: UrlSegment[]): Promise<boolean | UrlTree>
    {
        // Check the authentication status
        const res = await this._authService.check();
        if (res) {
            
            return true;
        } else {
            const redirectURL = `/${segments.join('/')}`;
            const urlTree = this._router.parseUrl(`sign-in?redirectURL=${redirectURL}`);
            // const urlTree = this._router.parseUrl(`sign-in`);
            return urlTree;
        }


        
        // .pipe(
        //     switchMap((authenticated) => {

        //         // If the user is not authenticated...
        //         if ( !authenticated )
        //         {
        //             // Redirect to the sign-in page with a redirectUrl param
        //             const redirectURL = `/${segments.join('/')}`;
        //             const urlTree = this._router.parseUrl(`sign-in?redirectURL=${redirectURL}`);

        //             return of(urlTree);
        //         }

        //         // Allow the access
        //         return of(true);
        //     })
        // );
    }
}
