import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserModel } from 'app/data/domain/models/user.model';
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { increment } from '@angular/fire/firestore';
import {
    DocAssignmentImplementationRepositoryMapper,
    DocImplementationRepositoryMapper,
} from 'app/data/base/doc-mapper';
import { User } from 'app/core/user/user.types';
import { LogService } from 'app/core/logs/log.service';
import { AssignmentEntity } from './entities/assignment-entity';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AssignmentRepository {
    // userMapper = new UserImplementationRepositoryMapper();
    docMapper = new DocAssignmentImplementationRepositoryMapper();
    COLLECTION_NAME = 'assignments';

    constructor(
        private afs: AngularFirestore,
        private _logService: LogService
    ) {}


    createAssignment(assignment: AssignmentEntity) {
        return new Promise((resolve, reject) => {
            this.afs.collection(this.COLLECTION_NAME.toString()).add(assignment)
                .then((us: any) => {
                    this._logService.createLog('CREATE', this.COLLECTION_NAME.toString(), assignment);
                    return resolve({...assignment});
                }).catch((err) => {
                    //console.log('err REPOSITORU', err);
                    return reject('Ocurrio un error al crear el registro de cesión');
                })
        });
    }

    getAssignments() {
        const users = this.afs
            .collection<AssignmentEntity[]>(this.COLLECTION_NAME, ref => ref.orderBy('created_at', 'desc'))
            .snapshotChanges()
            .pipe(
                map((actions) =>
                    actions.map((a) => {
                        const doc = this.docMapper.mapDoc(a.payload.doc);
                        return doc;
                    })
                )
            );

        return users;
    }

    getAssingmentByIdStatic(id: string) {
        return this.afs.doc<AssignmentEntity>(this.COLLECTION_NAME +'/'+ id).ref.get();
        // .snapshotChanges()
        // .pipe(map(this.docMapper.mapDoc), map(this.userMapper.mapFrom), map(this.docMapper.mapDocUserToUser));
    }

    async getInvoiceNumber() {
        const invoiceNumbe = await this.afs
            .collection('invoice')
            .doc(environment.pathInvoiceAssignment)
            .ref.update({
                invoiceNumber: increment(1),
            });

        const invoiceNumber: any = await this.afs
            .collection('invoice')
            .doc(environment.pathInvoiceAssignment)
            .ref.get();
        

        return invoiceNumber.data().invoiceNumber;
    }

    // Ventas
    getAssignmentsByAssignorId(assignorId: string) {
        const users = this.afs
            .collection<AssignmentEntity[]>(this.COLLECTION_NAME, (ref) =>
                ref.where('assignorId', '==', assignorId)
            )
            .snapshotChanges()
            .pipe(
                map((actions) =>
                    actions.map((a) => {
                        const doc = this.docMapper.mapDoc(a.payload.doc);
                        return doc;
                    })
                )
            );

        return users;
    }

    // Ventas
    async getAssignmentsByAssignorIdStatic(assignorId: string) {
        const docs = await this.afs
            .collection<AssignmentEntity[]>(this.COLLECTION_NAME)
            .ref.where('assignorId', '==', assignorId)
            .get();
        const wd = docs.docs.map((doc) => {
            const dd = doc.data();
            return { id: doc.id, ...dd };
        });

        return wd;
    }

    // Compras
    getAssignmentsByAssigneeId(assigneeId: string) {
        const users = this.afs
            .collection<AssignmentEntity[]>(this.COLLECTION_NAME, (ref) =>
                ref.where('assigneeId', '==', assigneeId)
            )
            .snapshotChanges()
            .pipe(
                map((actions) =>
                    actions.map((a) => {
                        const doc = this.docMapper.mapDoc(a.payload.doc);
                        return doc;
                    })
                )
            );

        return users;
    }

    // Compras
    async getAssignmentsByAssigneeIdStatic(assigneeId: string) {
        const docs = await this.afs
            .collection<AssignmentEntity[]>(this.COLLECTION_NAME)
            .ref.where('assigneeId', '==', assigneeId)
            .get();
        const wd = docs.docs.map((doc) => {
            const dd = doc.data();
            return { id: doc.id, ...dd };
        });

        return wd;
    }
}
