import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
})
export class DateInputComponent {
  @Input()
  label: string = '';
  
  @Input()
  name: string = '';

  @Input() 
  parentGroup: FormGroup;


  handleChange(eventFile) {

    const handleChangeInput = (event) => {
      event.preventDefault();
      var keyCode = event.keyCode || event.which;
      if (keyCode == '13'){
          console.log('enter pressed');
          return false;
      }
  }

  return handleChangeInput(eventFile);
}
}
