import { Router } from '@angular/router';
import { map, BehaviorSubject } from 'rxjs';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { DocImplementationRepositoryMapper } from 'app/data/base/doc-mapper';
import { UserImplementationRepositoryMapper } from 'app/data/repositories/user/mappers/user-repository.mapper';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Swal from 'sweetalert2';
import { MatSelectChange } from '@angular/material/select';

interface TableUser {
  // id: string;
  fullName: string;
  secondLastName: string;
  lastName: string;
  email: string;
  createdAt: Date;
  idRole: number;
}

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
})
export class UserTableComponent implements OnInit {

  //Models for Input fields
  nameValue: string;
  placeValue: string;

  //Data object for listing items
  tableData: User[] = [];

  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;
  totalItems = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;
  userMapper = new UserImplementationRepositoryMapper();
  docMapper = new DocImplementationRepositoryMapper();
  itemsPerPage = 20;
  totalPages = 1;

  COLLECTION_NAME = 'users';
  categories = [
    {
      title: 'Nombre',
      slug: 'full_name'
    },
    {
      title: 'Apellido paterno',
      slug: 'last_name'
    },
    {
      title: 'Apellido materno',
      slug: 'second_last_name'
    },
    {
      title: 'Correo electrónico',
      slug: 'email'
    },
    // {
    //   title: 'Id',
    //   slug: 'id'
    // }
  ];

  filters: {
    categorySlug$: BehaviorSubject<string>;
    query$: BehaviorSubject<string>;
    hideCompleted$: BehaviorSubject<boolean>;
  } = {
      categorySlug$: new BehaviorSubject('all'),
      query$: new BehaviorSubject(''),
      hideCompleted$: new BehaviorSubject(false)
    };


  holderText = 'Buscar por';
  selectedFilter = '';
  searchTerm = 0;
  searchStringTerm = '';

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private firestore: AngularFirestore, private _userService: UserService, private router: Router) {

  }

  async ngOnInit() {
    // this.totalItems = await this._userService.getTotalCountUsers();
    this.selectedFilter = this.categories[0].slug;
    this.holderText = 'Buscar por ' + this.categories[0].title;
    this.loadItems();
  }

  async getTotalItemConsoult() {

    //TODO: this
    if (this.selectedFilter === 'id' && this.searchStringTerm) {
      //console.log('searchStringTerm entr');
      const res = this.firestore.collection(this.COLLECTION_NAME.toString())
        .ref.where(this.selectedFilter, '==', this.searchStringTerm)
        .where('id_role', '<', 3).get()
      const dd = await res;
      this.totalItems = dd.size;
    }

    if (this.searchStringTerm) {
      //console.log('searchStringTerm entr');
      const res = this.firestore.collection(this.COLLECTION_NAME.toString())
        .ref.where(this.selectedFilter, '==', this.searchStringTerm.toLowerCase())
        .where('id_role', '<', 3).get()
      const dd = await res;
      this.totalItems = dd.size;
    }

    else {
      const res = this.firestore
        .collection(this.COLLECTION_NAME.toString()).ref
        .where('id_role','<', 3).get();
      const dd = await res;
      this.totalItems = dd.size;
    }
  }

  getFirstConsoult() {
    this.getTotalItemConsoult();
    if (this.selectedFilter === 'id' && this.searchStringTerm) {
      //console.log('Haciendo qery 2', this.searchStringTerm);
      this.selectedFilter
      //console.log('Haciendo qery 2', this.selectedFilter);

      return this.firestore.collection(this.COLLECTION_NAME.toString(),
        (ref) =>
          ref
            .where(this.selectedFilter, '==', this.searchStringTerm)
      )

    }

    if (this.searchStringTerm) {
      //console.log('this.selectedFilter', this.selectedFilter);
      //console.log('Haciendo qery 2 searchStringTerm', this.searchStringTerm);

      return this.firestore.collection(this.COLLECTION_NAME.toString(),
        (ref) =>
          ref
            .where(this.selectedFilter, '==', this.searchStringTerm.toLowerCase())
      )

    }

    return this.firestore
      .collection(this.COLLECTION_NAME.toString(), (ref) =>
        ref.where('id_role', '<', 3).limit(this.itemsPerPage)
      )

  }


  /**
  * Filter by category
  *
  * @param change
  */
  filterByCategory(change: MatSelectChange): void {
    // this.filters.categorySlug$.next(change.value);
    //console.log('Value', change.value);
    this.selectedFilter = change.value;
    const item = this.categories.find(c => c.slug === change.value)
    this.holderText = 'Buscar por ' + item.title;
  }


  /**
 * Track by function for ngFor loops
 *
 * @param index
 * @param item
 */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
  /**
    * Filter by search query
    *
    * @param query
    */
  filterByQuery(query: string): void {
    // this.filters.query$.next(query);
    //console.log('QUERY', query);
    this.searchStringTerm = query;
    this.loadItems();

  }


  onClickEditButton(id, idRole = 0) {
    //console.log('Click Button', id);
    if (idRole === 3) {
      this.router.navigateByUrl('/peoples/edit/' + id);
    } else {
      this.router.navigateByUrl('/users/edit/' + id);
    }
  }

  onClickDisableButton(user) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Está acción deshabilitará el acceso al usuario",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, deshabilitar!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: 'Procesando solicitud...',
          showConfirmButton: false,
        }
        )
        await this._userService.disableUser(user.id, user).then(() => {
          this.ngOnInit();
        });
        Swal.close();

        Swal.fire(
          'Deshabilitado!',
          'El usuario ha sido deshabilitado.',
          'success'
        )
      }
    })
  }

  onClickAvailableButton(user) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Está acción habilitará el acceso al usuario",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, habilitar!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: 'Procesando solicitud...',
          showConfirmButton: false,
        }
        )
        await this._userService.availableUser(user.id, user).then(() => {
          this.ngOnInit();
        });
        Swal.close();

        Swal.fire(
          'Habilitado!',
          'El usuario ha sido habilitado.',
          'success'
        )
      }
    })
  }


  loadItems() {
    this.getFirstConsoult().snapshotChanges()
      .subscribe(response => {
        if (!response.length) {
          //console.log("No Data Available");
          this.tableData = [];
          return false;
        }
        this.firstInResponse = response[0].payload.doc;
        this.lastInResponse = response[response.length - 1].payload.doc;

        const newtableData = [];
        for (let item of response) {
          const doc = this.docMapper.mapDoc(item.payload.doc);
          const doc2 = this.userMapper.mapFrom(doc)
          const doc3 = this.docMapper.mapDocUserToUser(doc2)
          //console.log('DOC3', doc3);
          newtableData.push(doc3);
        }
        this.tableData = newtableData;
        //console.log(this.tableData);

        //Initialize values
        this.prev_strt_at = [];
        this.pagination_clicked_count = 0;
        this.disable_next = false;
        this.disable_prev = false;

        const pageLeng = this.pagination_clicked_count + 1;
        const itemsLenght = this.tableData.length * pageLeng;
        //console.log('this.tableData.length', itemsLenght)
        //console.log('this.totalItems.length', this.totalItems)


        if (itemsLenght === this.totalItems) {
          this.disable_next = true;
        }

        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage)

        //Push first item to use for Previous action
        this.push_prev_startAt(this.firstInResponse);
      }, error => {
      });
  }


  //Show previous set 
  prevPage() {
    this.disable_prev = true;
    this.firestore.collection('users', ref => ref
    .where('id_role', '<', 3)  
    .limit(this.itemsPerPage)
      .startAt(this.get_prev_startAt())
      .endBefore(this.firstInResponse)
    ).get()
      .subscribe(response => {
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];

        const newtableData = [];
        for (let item of response.docs) {
          const doc = this.docMapper.mapDoc(item);
          const doc2 = this.userMapper.mapFrom(doc)
          const doc3 = this.docMapper.mapDocUserToUser(doc2)
          //console.log('DOC3', doc3);
          newtableData.push(doc3);
        }
        this.tableData = newtableData;
        //console.log(this.tableData);


        //Maintaing page no.
        this.pagination_clicked_count--;

        //Pop not required value in array
        this.pop_prev_startAt(this.firstInResponse);

        //Enable buttons again
        this.disable_prev = false;
        this.disable_next = false;
      }, error => {
        this.disable_prev = false;
      });
  }

  nextPage() {
    this.disable_next = true;
    this.firestore.collection('users', ref => ref
    .where('id_role', '<', 3)
      .limit(this.itemsPerPage)
      .startAfter(this.lastInResponse)
    ).get()
      .subscribe(response => {

        if (!response.docs.length) {
          this.disable_next = true;
          return;
        }

        this.firstInResponse = response.docs[0];

        this.lastInResponse = response.docs[response.docs.length - 1];
        const newtableData = [];
        for (let item of response.docs) {
          const doc = this.docMapper.mapDoc(item);
          const doc2 = this.userMapper.mapFrom(doc)
          const doc3 = this.docMapper.mapDocUserToUser(doc2)
          //console.log('DOC3', doc3);
          newtableData.push(doc3);
        }

        this.tableData = newtableData;
        //console.log(this.tableData);


        this.pagination_clicked_count++;

        this.push_prev_startAt(this.firstInResponse);

        this.disable_next = false;
      }, error => {
        this.disable_next = false;
      });
  }

  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }

  //Remove not required document 
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach(element => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }

  //Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > (this.pagination_clicked_count + 1))
      this.prev_strt_at.splice(this.prev_strt_at.length - 2, this.prev_strt_at.length - 1);
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }

  //Date formate
  readableDate(time) {
    var d = new Date(time);
    return d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear();
  }

}
