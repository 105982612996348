import { SubdivisionEntity } from './../../data/repositories/subdivision/entities/subdivision-entity';
import { AssignmentsService } from 'app/core/assignments/assignments.service';
import { UserService } from 'app/core/user/user.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, finalize } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { UserRepository } from 'app/data/repositories/user/user.repository';
import { UserModel } from 'app/data/domain/models/user.model';
import { DocImplementationRepositoryMapper } from 'app/data/base/doc-mapper';
import { UserImplementationRepositoryMapper } from 'app/data/repositories/user/mappers/user-repository.mapper';
import { environment } from 'environments/environment';
import { UserEntity } from 'app/data/repositories/user/entities/user-entity';
import { FileUpload } from './subdivision.types';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AssignmentRepository } from 'app/data/repositories/assignment/assignment.repository';
import { AssignmentEntity } from 'app/data/repositories/assignment/entities/assignment-entity';
import { SubdivisionRepository } from 'app/data/repositories/subdivision/subdivision.repository';

@Injectable({
    providedIn: 'root',
})
export class SubdivisionService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    userMapper = new UserImplementationRepositoryMapper();
    docMapper = new DocImplementationRepositoryMapper();

    private basePath = '/attachments/subdivisions';
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        public auth: AngularFireAuth,
        private _userService: UserService,
        private _subdivisionRepository: SubdivisionRepository,
        private _assignmentRepository: AssignmentsService,
        private storage: AngularFireStorage
    ) { }



    getSubdivisions(): Observable<SubdivisionEntity[]> {
        return this._subdivisionRepository.getSubdivisions();
    }

    async getSubdivisionById(id: string): Promise<SubdivisionEntity> {
        let prom1: any = await this._subdivisionRepository.getSubdivisionByIdStatic(id);

        const d: any = prom1.data();
        const doc = {
            id: prom1.id,
            ...d
        }
        return doc;
    }

    pushFileToStorage(
        fileUpload: FileUpload,
        invoice
    ): Promise<{ name: string; url: string }> {
        //console.log('FILE Upload: ', fileUpload);
        return new Promise((resolve, reject) => {
            const namedFile = `${invoice}-${new Date().getTime()}`;
            const filePath = `${this.basePath}/${namedFile}`;
            const storageRef = this.storage.ref(filePath);
            const uploadTask = this.storage.upload(filePath, fileUpload);

            uploadTask
                .snapshotChanges()
                .pipe(
                    finalize(() => {
                        storageRef.getDownloadURL().subscribe((downloadURL) => {
                            // fileUpload.url = downloadURL;
                            // fileUpload.name = fileUpload.file.name;
                            //console.log('downloadURL', downloadURL);
                            resolve({ name: namedFile, url: downloadURL });
                            // this.saveFileData(fileUpload);
                        });
                    })
                )
                .subscribe();

            uploadTask.percentageChanges().subscribe((percentage: any) => {
                // //console.log('PERCENTAJE', percentage)
                percentage = Math.round(percentage);
                // //console.log(percentage);
                if (percentage === 100) {
                    // //console.log('listo');
                }
            });
        });
    }

    createSubdivision(form, files) {
        return new Promise(async (resolve, reject) => {
            // Validando si el usuario cuenta con hectareas disponibles para ceder
            const dataAssignment = await this._assignmentRepository.getAssignmentById(form.invoiceNumberId);
            const selles = await this._subdivisionRepository.getSubdivionsOfAssignmentsByIdAssignmentStatic(form.invoiceNumberId)

            // Obtener el total de metros vendidos como fraccionamientos
            const mtsSells = Number(await this.getMtsSells(selles));
            //console.log('mts vendidos', mtsSells);
            //console.log('mts TOTALES',Number(dataAssignment.meters) )
            let saldoAvailable = false;

            // verificar si aun se pueden vender hectareas por disponibilidad
            if (Number(dataAssignment.meters) > mtsSells) {
                //console.log('sI PUEDE VENDER')
                saldoAvailable = true;
            }
        
            //console.log('Saldo disponible', saldoAvailable);
            if (!saldoAvailable) {
                return reject({
                    message:
                        'No se puede completar el registro debido a que el Folio de captura seleccionado no cuenta con los metros disponibles',
                });
            }


            const saldoRestante = Number(dataAssignment.meters) - mtsSells;
            if (saldoRestante < Number(form.meters)) {
                return reject({
                    message:
                        'No se puede completar el registro debido a que el Folio de captura seleccionado no cuenta con los metros disponibles',
                });
            }

            const invoice =
                await this._subdivisionRepository.getInvoiceNumber();
            //console.log('INVOCIE', invoice);

            //Subiendo archivos
            const responseUrls = await this.uploadFilesToFirestorage(
                files,
                invoice
            );
            //console.log('RESPONSE IURS', responseUrls);

            // Creando modelo para completar proceso

            const model: SubdivisionEntity = {
                files: responseUrls,
                invoiceNumber: invoice,
                invoiceNumberOfAssignment: Number(dataAssignment.invoiceNumber),
                assignorId: form.assignor,
                assigneeId: form.assignee,
                agriculturalQualityOfAssignee: Number(
                    form.agriculturalQualityOfAssignee
                ),
                agriculturalQualityOfAssignor: Number(
                    form.agriculturalQualityOfAssignor
                ),
                administration: form.administration,
                meters: form.meters,
                observations: form.observations,
                expeditionDate: new Date(form.expeditionDate),
                created_at: new Date(),
                updated_at: new Date(),
                location: form.location,
                name: form.subdivisionName.toLowerCase(),
                idAssignment: form.invoiceNumberId
            };

            try {
                this._subdivisionRepository
                    .createSubdivision(model)
                    .then((response) => {
                        //console.log('Data updated RESPONSE', response);
                        return resolve(response);
                    })
                    .catch((err) => {
                        return reject({ err: err, message: err });
                    });
            } catch (error) {
                return reject({ err: error, message: 'Ocurrio un error al procesar la solicitud' });
            }

        });
        // Validate if user have hectares
    }

    async getTotalSubdivisionsSellsByIdUser(idUser: string) {
        const selles = await this._subdivisionRepository.getSubdivisionsByAssignorIdStatic(
            idUser
        );

        const mtsSells = await this.getMtsSells(selles);
        //console.log('mtsSells', mtsSells);
        const total = mtsSells;
        return total.toFixed(2);
    }

    async getTotalSubdivisionsBuyedByIdUser(idUser: string) {
        const buys =
            await this._subdivisionRepository.getSubdivisionsByAssigneeIdStatic(
                idUser
            );

        const mtsBuyed = await this.getMtsBuyed(buys);
        //console.log('mtsBuyed', mtsBuyed);

        const total = mtsBuyed;
        return total.toFixed(2);
    }

    async uploadFilesToFirestorage(files: any[], invoice) {
        let filesData = [];
        for (const iterator of files) {
            const responseData = await this.pushFileToStorage(
                iterator.file,
                invoice
            );
            filesData.push(responseData);
        }

        return filesData;
    }

    getMtsBuyed(buys) {
        let mtsBuyed = 0;
        for (let index = 0; index < buys.length; index++) {
            const element = buys[index];
            mtsBuyed = mtsBuyed + element.meters;
        }

        return mtsBuyed;
    }

    getMtsSells(buys) {
        let mtsBuyed = 0;
        if (buys.length === 0) {
            return 0;
        }
        for (let index = 0; index < buys.length; index++) {
            const element = buys[index];
            mtsBuyed = mtsBuyed + element.meters;
        }

        return mtsBuyed;
    }

    getTotalCountSubdivisions(): Promise<number> {
        return this._subdivisionRepository.getTotalCountSubdivisions();
    }
}
