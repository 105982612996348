<div [formGroup]="parentGroup">
    <mat-form-field class="w-full">
        <mat-label>Fecha de expedición</mat-label>
        <input  [name]="name" (onKeyPress)="handleChange($event)" 
        [formControlName]="name"  matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    
        <!-- <input
            matInput
            [placeholder]="'Selecciona la fecha de nacimiento'"> -->
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
    </mat-form-field>
</div>
