import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { UserRepository } from 'app/data/repositories/user/user.repository';
import { UserModel } from 'app/data/domain/models/user.model';
import { DocImplementationRepositoryMapper } from 'app/data/base/doc-mapper';
import { UserImplementationRepositoryMapper } from 'app/data/repositories/user/mappers/user-repository.mapper';
import { environment } from 'environments/environment';
import { UserEntity } from 'app/data/repositories/user/entities/user-entity';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    userMapper = new UserImplementationRepositoryMapper();
    docMapper = new DocImplementationRepositoryMapper();
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        public auth: AngularFireAuth,
        private _userRepository: UserRepository
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    // async get() {
    //     // this._httpClient.get('api/common/user').
    //     const prom1 = this.auth.currentUser;

    //     let res = await Promise.all([prom1]);

    //     const prom2 = this._userRepository.getUserById(res[0].uid).pipe(tap((user) => {
    //         //console.log('USER', user);
    //         this._user.next(user);
    //     })).toPromise();

    //     let res2 = await Promise.all([prom2]);
    //     return res2;
    // }

    /**
     * Get the current logged in user data
     */
    async getUser(id: string) {
        // // this._httpClient.get('api/common/user').
        // const prom1 = this.auth.currentUser;

        // let res = await Promise.all([prom1]);
        let prom1: any = await this._userRepository.getUserByIdStatic(id);
        map(this.docMapper.mapDoc), map(this.userMapper.mapFrom), map(this.docMapper.mapDocUserToUser)
        prom1 = this.docMapper.mapDoc(prom1);
        prom1 = this.userMapper.mapFrom(prom1);
        prom1 = this.docMapper.mapDocUserToUser(prom1);
        // //console.log('PROM1', prom1);
        this._user.next(prom1);
        return prom1;
    }

    async getUserById(id: string): Promise<User> {
        let prom1: any = await this._userRepository.getUserByIdStatic(id);
        prom1 = this.docMapper.mapDoc(prom1);
        prom1 = this.userMapper.mapFrom(prom1);
        prom1 = this.docMapper.mapDocUserToUser(prom1);
        return prom1;
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    createExternalUser(user): Promise<any> {
        return new Promise(async (resolve, reject) => {       
            const model: UserEntity = {
                date_of_birth: new Date(user.dateOfBirth),
                email: user.email.toLowerCase(),
                full_name: user.name.toLowerCase(),
                second_last_name: user.secondLastName.toLowerCase(),
                last_name: user.lastName.toLowerCase(),
                id_role: user.role,
                created_at: new Date(),
                updated_at: new Date(),
                status: true,
            };

            const usersCollection = await this._userRepository
                .getUserWithNames(
                    model.full_name.toLowerCase(), 
                    model.last_name.toLowerCase(), 
                    model.second_last_name.toLowerCase());

            const users = usersCollection.docs.map((d) => d.data());
            
            if (users.length > 0) {
                return reject({message: 'Ya existe un usuario con el mismo nombre'});
            }
            
            const usersCollectionWithEmail = await this._userRepository.getUserWithEmail(model.email.toLowerCase());
            const usersWithEmail = usersCollectionWithEmail.docs.map((d) => d.data());

            if (usersWithEmail.length > 0) {
                return reject({message: 'Ya existe un usuario con el mismo email'});
            }

            this._userRepository.createUser(model).then(
                (response) => {
                    //console.log('Data updated');
                    return resolve(response);
                }
            ).catch((err) => reject({err: err, message: err}));
        });
    }

    updateUserById(id, user): Promise<any> {
        return new Promise(async (resolve, reject) => {     
            const model: UserEntity = {
                date_of_birth: new Date(user.dateOfBirth),
                full_name: user.name.toLowerCase(),
                second_last_name: user.secondLastName.toLowerCase(),
                last_name: user.lastName.toLowerCase(),
                id_role: user.role,
                updated_at: new Date(),
            };

            const usersCollection = await this._userRepository
                .getUserWithNames(
                    model.full_name.toLowerCase(),
                    model.last_name.toLowerCase(),
                    model.second_last_name.toLowerCase());

            const users = usersCollection.docs.map(d => {
                const doc: any = d.data();
                const id = d.id;
                const obj = {
                    id, ...doc
                }
                return obj;
                return d;
            });

            const newUsers = users.filter(x => x.id !== id)

            if (newUsers.length > 0) {
                return reject({ message: 'Existe un usuario con el mismo nombre' });
            }

            this._userRepository.updateUserById(id, model).then(
                (response) => {
                    //console.log('Data updated');
                    return resolve(response);
                }
            ).catch((err) => reject({err: err, message: err}));
        });
    }

    getUsers(): Observable<User[]> {
        return this._userRepository.getUsers();
    }

    getPeoples(): Observable<User[]> {
        return this._userRepository.getPeoples();
    }

    disableUser(id, user: User) {
        const us: UserEntity = {
            status: false
        }
        return this._userRepository.updateUserById(id, us);
    }

    availableUser(id, user: User) {
        const us: UserEntity = {
            status: true
        }
        return this._userRepository.updateUserById(id, us);
    }

    getUsersTable() {
        return this._userRepository.getUsersPagination(1, 'full_name');
    }

    getNextUsersTable(startAfter: any) {
        return this._userRepository.getNextUsersPagination(1, 'full_name', startAfter);
    }

    getPrevUsersTable(startAt: any, endAt: any) {
        return this._userRepository.getPrevUsersPagination(1, 'full_name',startAt, endAt);
    }

    getTotalCountUsers(): Promise<number> {
        return this._userRepository.getTotalCountUsers();
    }

    getTotalCountPeople(): Promise<number> {
        return this._userRepository.getTotalCountPeoples();
    }
}
