<div class="container">
    <!-- <div class="text-center">
      <button class="btn btn btn-info btn-sm float-left" (click)="prevPage()"
        [disabled]="disable_prev || !(pagination_clicked_count>0)">Previous</button> <b>Page no:
        {{pagination_clicked_count+1}}</b>
      <button class="btn btn btn-info btn-sm float-right" (click)="nextPage()" [disabled]="disable_next">Next</button>
    </div> -->
    <div class="flex flex-col sm:flex-row items-center justify-between w-full max-w-xs sm:max-w-none">
      <mat-form-field class="w-full sm:w-36" [subscriptSizing]="'dynamic'">
        <mat-select [value]="categories[0].slug" (selectionChange)="filterByCategory($event)">
          <ng-container *ngFor="let category of categories">
            <mat-option [value]="category.slug">{{category.title}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <div *ngIf="selectedFilter !== 'date'">
        <mat-form-field class="w-full sm:w-72 mt-4 sm:mt-0 sm:ml-4" [subscriptSizing]="'dynamic'">
          <mat-icon matPrefix class="icon-size-5" [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input (change)="filterByQuery(query.value)" [placeholder]="holderText" matInput #query>
        </mat-form-field>
      </div>
      <div *ngIf="selectedFilter === 'date'">
        <mat-form-field class="w-full sm:w-72 mt-4 sm:mt-4 sm:ml-4">
          <input (dateChange)="onChangeDate($event)" matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>

          <!-- <input
              matInput
              [placeholder]="'Selecciona la fecha de nacimiento'"> -->
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
      </mat-form-field>
        <!-- <mat-form-field>
          <mat-icon matPrefix class="icon-size-5" [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input (change)="filterByQuery(query.value)" [placeholder]="'ingresar fecha'" matInput #query>
        </mat-form-field> -->
      </div>
      <div class="mt-8 sm:mt-0 sm:ml-auto">
        <button styleSheetFile="assets/pdf.css" printSectionId="print-section" [useExistingCss]="true" ngxPrint
          class="ml-3 " color="primary" mat-flat-button>
          <mat-icon class="icon-size-5 mr-2" [svgIcon]="'heroicons_solid:printer'"></mat-icon>
          Imprimir
        </button>
      </div>
    </div>
  
    <div class="flex flex-col" id="print-section">
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden">
            <table class="min-w-full">
              <thead class="border-b">
                <tr>
                    <th scope="col" class="text-base font-bold px-6 py-4 text-left">
                        Id de usuario
                    </th>
                  <th scope="col" class="text-base font-bold  px-6 py-4 text-left">
                    Acción
                  </th>
                  <th scope="col" class="text-base  font-bold  px-6 py-4 text-left">
                    Fecha
                  </th>
                  <th scope="col" class="text-base font-bold  px-6 py-4 text-left">
                    Sección
                  </th>
                  <th scope="col" class="text-base font-bold  px-6 py-4 text-left">
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of tableData" class="bg-white border-b">
                  <td class="px-6 py-4 whitespace-nowrap text-base">
                    {{item.userId}}
                  </td>
                  <td class="text-base px-6 py-4 whitespace-nowrap">
                    {{item.action === 'CREATE' ? 'CREAR': ''}}
                    {{item.action === 'UPDATE' ? 'EDITAR': ''}}
                  </td>
                  <td class="text-base  px-6 py-4 whitespace-nowrap">
                    {{item.date.toLocaleString()}}
                  </td>
                  <td class="text-base px-6 py-4 whitespace-nowrap">
                    {{item.table.toUpperCase()}}
                  </td>
                  <td class="text-base px-6 py-4 whitespace-nowrap">
                        <button class="px-6 ml-3" mat-flat-button
                          [color]="'accent'"
                          (click)="onClickViewButton(item.id, item.idRole)" 
                          >
                          <span>
                            Ver detalle
                          </span>
                        </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-5">
      <div class="flex flex-col items-center">
        <!-- Help text -->
        <span class="text-sm text-gray-700 dark:text-gray-400">
          Pagina número
          <span class="font-semibold text-gray-900 dark:text-white">
            {{pagination_clicked_count+1}}
          </span>
          de
          <span class="font-semibold text-gray-900 dark:text-white">
            {{ totalPages }}
          </span>
        </span>
        <div class="inline-flex mt-2 xs:mt-0">
          <!-- Buttons -->
          <button (click)="prevPage()" [disabled]="disable_prev || !(pagination_clicked_count>0)"
            [ngClass]="{'disabled:opacity-25': disable_prev || !(pagination_clicked_count>0)}"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clip-rule="evenodd"></path>
            </svg>
            Página anterior
          </button>
          <button [disabled]="disable_next" (click)="nextPage()" [ngClass]="{'disabled:opacity-25': disable_next}"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 border-0 border-l border-gray-700 rounded-r hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            Página siguiente
            <svg aria-hidden="true" class="w-5 h-5 ml-2" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>
      </div>

    </div>
  </div>