import { MatPaginatorIntl } from "@angular/material/paginator";

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Elementos por página:';
  customPaginatorIntl.lastPageLabel = "Última página";
  customPaginatorIntl.nextPageLabel = "Página siguiente";
  customPaginatorIntl.firstPageLabel = "Primera página";
  customPaginatorIntl.previousPageLabel = "Página anterior";

  return customPaginatorIntl;
}