import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ILog } from '../../../../functions/src/models/log.interface';
import { LogRepository } from 'app/data/repositories/log/log.repository';

@Injectable({
    providedIn: 'root'
})
export class LogService {

    /**
     * Constructor
     */
    constructor(
        public auth: AngularFireAuth,
        private _logRepository: LogRepository
    ) {
    }

    async createLog(action: 'CREATE' | 'UPDATE', table: string, payload: any, folio?: string) {
        const us = await this.auth.currentUser;
        us.uid;

        const log: ILog = {
            action: action,
            table: table,
            date: new Date(),
            userId: us.uid,
            payload: payload           
        };

        if (folio) {
            log.folio = folio;
        }

        this._logRepository.createLog(log);
        
    }

}
