import { Injectable } from '@angular/core';
import { getFunctions, provideFunctions, httpsCallable, connectFunctionsEmulator } from '@angular/fire/functions';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor() { }

  generateReport(id: string, type: 'possessors' | 'people' ): Promise<any> {
    return new Promise((resolve, reject) => { 
      try {   
        const functions = getFunctions();
        const addMessage = httpsCallable(functions, 'onGeneratePeopleDocument');
        // possessors people
        // connectFunctionsEmulator(functions, "localhost", 5001);
        addMessage({idUser: id, type})
        .then((result: any) => {
          // Read result of the Cloud Function.
          console.log(result);
          const source = `data:application/pdf;base64,${result.data.blobFile}`;
          const link = document.createElement("a");
          link.href = source;
          link.download = `reporte-${result.data.user.full_name}_${result.data.user.last_name}_${result.data.user.second_last_name}-${new Date().valueOf()}.pdf`
          link.click();
          return resolve(true);
        });
      } catch (error) {
        return reject(error);
      }
     })
  }
}
