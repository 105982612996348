import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ERROR_MESSAGES } from 'app/core/constants/forms/error_messages';

@Component({
  selector: 'app-generic-input',
  templateUrl: './generic-input.component.html',
})
export class GenericInputComponent {
  @Input()
  label: string = '';
  
  @Input()
  name: string = '';

  @Input()
  placeholder: string = '';

  @Input()
  svgIcon: string = '';

  @Input()
  disabled: boolean = false;

  @Input() parentGroup: FormGroup;

  @Input() type: string = 'text';

  error_messages = ERROR_MESSAGES;

  constructor( private formBuilder: FormBuilder) {
  }
}
