import { PeopleBasicTableComponent } from './components/people-basic-table/people-basic-table.component';
import { PossessorsBasicTableComponent } from './components/possessor-basic-table/possessor-basic-table.component';
import { PossessorsTableComponent } from './components/possessor-table/possessor-table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericInputComponent } from './components/generic-input/generic-input.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UserTableComponent } from './components/user-table/user-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { CustomPaginator } from './components/user-table/CustomPaginatorConfiguration';
import { PeopleTableComponent } from './components/people-table/people-table.component';
import { LogsTableComponent } from './components/logs-table/logs-table.component';
import { AssignmentTableComponent } from './components/assignment-table/assignment-table.component';
import { SubdivisionTableComponent } from './components/subdivision-table/subdivision-table.component';
import { AssignmentSubdivisionsTableComponent } from './components/assignment-subdivisions-table/assignment-subdivisions-table.component';
import { AssignmentPeopleSelledComponent } from './components/assignment-people-selled/assignment-people-selled.component';
import { AssignmentPeopleBulledComponent } from './components/assignment-people-bulled/assignment-people-bulled.component';
import { AssignmentSubdivisionPeopleBulledComponent } from './components/assignment-subdivision-people-bulled/assignment-subdivision-people-bulled.component';
import { AssignmentSubdivisionPeopleSelledComponent } from './components/assignment-subdivision-people-selled/assignment-subdivision-people-selled.component';
import {NgxPrintModule} from 'ngx-print';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NoPossessorPipe } from './components/people-table/no-possessor.pipe';
import { DateInputComponent } from './components/date-input/date-input.component';
import { NgxLoadingModule } from "ngx-loading";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatButtonModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatNativeDateModule, 
        MatMomentDateModule,
        MatDatepickerModule,
        NgxPrintModule,
        NgxLoadingModule.forRoot({}),
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GenericInputComponent,
        UserTableComponent,
        PeopleTableComponent,
        PossessorsTableComponent,
        LogsTableComponent,
        AssignmentTableComponent,
        SubdivisionTableComponent,
        AssignmentSubdivisionsTableComponent,
        AssignmentPeopleSelledComponent,
        AssignmentPeopleBulledComponent,
        AssignmentSubdivisionPeopleBulledComponent,
        AssignmentSubdivisionPeopleSelledComponent,
        DateInputComponent,
        PossessorsBasicTableComponent,
        PeopleBasicTableComponent
    ],
    declarations: [
      GenericInputComponent,
      UserTableComponent,
      PeopleTableComponent,
      PossessorsTableComponent,
      LogsTableComponent,
      AssignmentTableComponent,
      SubdivisionTableComponent,
      AssignmentSubdivisionsTableComponent,
      AssignmentPeopleSelledComponent,
      AssignmentPeopleBulledComponent,
      AssignmentSubdivisionPeopleBulledComponent,
      AssignmentSubdivisionPeopleSelledComponent,
      NoPossessorPipe,
      DateInputComponent,
      PossessorsBasicTableComponent,
      PeopleBasicTableComponent
    ],
    providers: [
        { provide: MatPaginatorIntl, useValue: CustomPaginator() },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
    ]
})
export class SharedModule
{
}
