import { ILog } from './../../../../../functions/src/models/log.interface';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
    providedIn: 'root',
})
export class LogRepository {

    COLLECTION_NAME = 'logs';

    constructor(private afs: AngularFirestore) {
    }

    createLog(log: ILog) {
        return new Promise((resolve, reject) => {
            this.afs.collection(this.COLLECTION_NAME.toString()).add(log)
                .then((us) => {
                    //console.log('US', us);
                    resolve(us)
                }).catch((err)=> {
                    //console.log('err', err);
                    reject('Ocurrio un error al crear el log');
                })
        });
    }
}