import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, lastValueFrom } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        public auth: AngularFireAuth
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Promise<any> {
        return this.auth.sendPasswordResetEmail(email);
        // return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Promise<any> {
        // Throw error, if the user is already logged in
        return new Promise((resolve, reject) => {
            if (this._authenticated) {
                return reject('User is already logged in.');
            }

            this.auth.signInWithEmailAndPassword(credentials.email, credentials.password).then(
                (response: any) => {
                    //console.log('RESOLVE', response);
                    this._authenticated = true;
                    // this._userService.user = response.user;
                    this._userService.getUser(response.user.uid);
                    resolve(response);
                }
            ).catch((err) => {
                reject(err);
            });
        });

        // return this._httpClient.post('api/auth/sign-in', credentials).pipe(
        //     switchMap((response: any) => {

        //         // Store the access token in the local storage
        //         this.accessToken = response.accessToken;

        //         // Set the authenticated flag to true
        //         this._authenticated = true;

        //         // Store the user on the user service
        //         this._userService.user = response.user;

        //         // Return a new observable with the response
        //         return of(response);
        //     })
        // );
    }

    /**
     * Sign out
     */
    signOut(): Promise<any> {
        // Remove the access token from the local storage
        return new Promise((resolve, reject) => {
            this.auth.signOut().then((response: any) => {
                this._authenticated = false;
                localStorage.removeItem('accessToken');
                return resolve(true);
            }).catch((err) => {
                return reject(false);
                ;
            })
        });

        // Set the authenticated flag to false

        // Return the observable
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    async check(): Promise<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return true;
        }

        return this.signInUsingToken();
    }

     /**
     * Sign in using the access token
     */
     async signInUsingToken(): Promise<boolean>
     {
        return new Promise((resolve, reject) => {     
            this.auth.onAuthStateChanged(async (usa) => {
                if (!usa) {
                    return resolve(false);
                }
                await this._userService.getUser(usa.uid);
                this._authenticated = true;
                return resolve(true);
            });
        });
         // Sign in using the token

        return true;

        //  return this._httpClient.post('api/auth/sign-in-with-token', {
        //      accessToken: this.accessToken
        //  }).pipe(
        //      catchError(() =>
 
        //          // Return false
        //          of(false)
        //      ),
        //      switchMap((response: any) => {
 
        //          // Replace the access token with the new one if it's available on
        //          // the response object.
        //          //
        //          // This is an added optional step for better security. Once you sign
        //          // in using the token, you should generate a new one on the server
        //          // side and attach it to the response object. Then the following
        //          // piece of code can replace the token with the refreshed one.
        //          if ( response.accessToken )
        //          {
        //              this.accessToken = response.accessToken;
        //          }
 
        //          // Set the authenticated flag to true
        //          this._authenticated = true;
 
        //          // Store the user on the user service
        //          this._userService.user = response.user;
 
        //          // Return true
        //          return of(true);
        //      })
        //  );
     }
}
