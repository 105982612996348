import { UsersModule } from './modules/admin/users/users.module';
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
// import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AngularFireAuthGuard, redirectLoggedInTo,
    redirectUnauthorizedTo, } from '@angular/fire/compat/auth-guard';
import { OnlyAdminGuard } from './core/auth/guards/OnlyAdmin.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['sign-in']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['dashboard']);

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [AngularFireAuthGuard],
        // canActivateChild: [AngularFireAuthGuard],
        component: LayoutComponent,
        data: {
            authGuardPipe: redirectLoggedInToHome,
            layout: 'empty'
        },
        children: [
            // {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            // {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            // {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        canActivate: [AngularFireAuthGuard],
        // canActivateChild: [AngularFireAuthGuard],
        component: LayoutComponent,
        data: {
            authGuardPipe: redirectUnauthorizedToLogin,
            layout: 'empty',
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },
    // Admin routes
    {
        path       : '',
        canMatch: [AuthGuard],
        canActivate: [AngularFireAuthGuard],
        // canActivateChild: [AngularFireAuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        data: {
            authGuardPipe: redirectUnauthorizedToLogin
        },
        children   : [
            {path: 'dashboard', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)},
            {path: 'users', loadChildren: () => import('app/modules/admin/users/users.module').then(m => m.UsersModule)},
            {path: 'peoples', loadChildren: () => import('app/modules/admin/peoples/peoples.module').then(m => m.PeoplesModule)},
            {path: 'possessors', loadChildren: () => import('app/modules/admin/possessors/possessors.module').then(m => m.PossessorsModule)},
            {path: 'logs', loadChildren: () => import('app/modules/admin/logs/logs.module').then(m => m.LogsModule)},
            {path: 'assignments', loadChildren: () => import('app/modules/admin/assignments/assignments.module').then(m => m.AssignmentsModule)},
            {path: 'subdivisions', loadChildren: () => import('app/modules/admin/subdivisions/subdivisions.module').then(m => m.SubdivisionsModule)},
            {path: 'reports', loadChildren: () => import('app/modules/admin/reports/reports.module').then(m => m.ReportsModule)},
        ]
    }
];
