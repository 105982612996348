/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

const navigationItems: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },
    {
        id   : 'assignments',
        title: 'Registro de cesiones',
        type : 'basic',
        icon : 'heroicons_outline:clipboard',
        link : '/assignments'
    },
    {
        id   : 'subdivisions',
        title: 'Registro de fraccionamientos',
        type : 'basic',
        icon : 'heroicons_outline:book-open',
        link : '/subdivisions'
    },
    {
        id   : 'peoples',
        title: 'Ejidatarios',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/peoples'
    },
    {
        id   : 'possessors',
        title: 'Posesionarios',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/possessors'
    },
    {
        id   : 'users',
        title: 'Usuarios',
        type : 'basic',
        icon : 'heroicons_outline:users',
        link : '/users'
    }, 
    {
        id   : 'reports',
        title: 'Reportes',
        type : 'basic',
        icon : 'heroicons_solid:printer',
        link : '/reports'
    },
    {
        id   : 'logs',
        title: 'Historial de movimientos',
        type : 'basic',
        icon : 'heroicons_outline:eye',
        link : '/logs'
    },
]

export const defaultNavigation: FuseNavigationItem[] = navigationItems;
export const compactNavigation: FuseNavigationItem[] = navigationItems;
export const futuristicNavigation: FuseNavigationItem[] = navigationItems;
export const horizontalNavigation: FuseNavigationItem[] = navigationItems;


export const navigationForOwner: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },
]

export const navigationForEditor: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },
    {
        id   : 'assignments',
        title: 'Registro de cesiones',
        type : 'basic',
        icon : 'heroicons_outline:clipboard',
        link : '/assignments'
    },
    {
        id   : 'subdivisions',
        title: 'Registro de fraccionamientos',
        type : 'basic',
        icon : 'heroicons_outline:book-open',
        link : '/subdivisions'
    },
    {
        id   : 'peoples',
        title: 'Ejidatarios',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/peoples'
    },
    {
        id   : 'possessors',
        title: 'Posesionarios',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/possessors'
    },
    {
        id   : 'reports',
        title: 'Reportes',
        type : 'basic',
        icon : 'heroicons_solid:printer',
        link : '/reports'
    },
]